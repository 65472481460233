import "./FooterContainer.scss";
import { Typography } from "@aiops/styleguide";
import FooterLink from "../FooterLink";
import { documents } from './config';

/**
 *  Container that holds the contents of the footer.
 */
const FooterContainer = () => {
  return (
    <div className="row platform-footer">
      <Typography variant="paragraph1">
        © 2024
      </Typography>
      <div className="row">
        {documents.map((link, index) => (
          <FooterLink
            key={link.text}
            link={link}
            hasLeftBorder={index !== 0}
          />
        ))}
      </div>
    </div>
  );
};

export default FooterContainer;
