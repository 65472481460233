import { Typography } from "@aiops/styleguide";
import { useState } from 'react';
import "./FooterLink.scss";
import DocumentModal from "../DocumentModal";

type LinkType = 'HYPERLINK' | 'PDF';

/**
 * Possible document types to fetch.
 */
export type DocTypes = 'TERMS_AND_CONDITIONS'
  | 'POLICY_STATEMENTS'
  | 'THIRD_PARTY_NOTICES';

export type Link = {
  text: string;
  linkType: LinkType;
  url?: string;
  docType?: DocTypes;
}

/**
 * Type for props of FooterLink component.
 * 
 * @field text
 * Display text for the link.
 * 
 * @field docType
 * The document type. This is used to determine which document to fetch from the
 * API and must match an expected value on the backend or the request will fail.
 * 
 * @field hasLeftBorder
 * If true, link will have a left border. False by default.
 * 
 */
export type FooterLinkProps = {
  link: Link;
  hasLeftBorder?: boolean;
};

/**
 * Returns a link that is displayed in the footer, given a specified text and
 * url. Optionally include a border on the left side. Link can open in a new tab
 * (by default) or not.
 */
const FooterLink = ({
  link,
  hasLeftBorder = false,
}: FooterLinkProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const pdfLink = (link: Link) => (
    <button
      data-testid="footer-link-btn"
      onClick={() => setModalOpen(true)}
    >
      <Typography variant="paragraph1" >
        {link.text}
      </Typography>
    </button>
  )

  const hyperlink = (link: Link) => (
    <a
      data-testid="footer-link-a"
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography variant="paragraph1" >
        {link.text}
      </Typography>
    </a>
  )

  return (
    <>
      <DocumentModal
        modalIsOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        docType={link.docType}
      />
      <div className={`footer-link ${hasLeftBorder ? "border-left" : ""}`}>
        {link.linkType === 'HYPERLINK' ? hyperlink(link) : pdfLink(link)}
      </div>
    </>
  );
};

export default FooterLink;
