import FooterContainer from "./components/FooterContainer/FooterContainer";
import { AppWrapper, AuthProvider } from "@aiops/styleguide";

export default function Root(props) {
  return (
    <AppWrapper fullWindowHeight={false}>
      <AuthProvider requireAuth={false}>
        <FooterContainer />
      </AuthProvider>
    </AppWrapper >
  )
}
