import { useEffect, useState } from "react";
import {
  Modal,
  CircularProgress,
  useAuth,
  Button,
  Typography,
} from "@aiops/styleguide";
import { DocTypes } from "../FooterLink";
import "./DocumentModal.scss";
import { getConfig } from "@aiops/root-config";

type DocumentModalContentsProps = {
  closeModal: () => void,
  docType: DocTypes,
}

/**
 * The contents of the document modal.
 * 
 * This component will only ever be seen within the DocumentModal component.
 * 
 */
export const DocumentModalContents = ({ closeModal, docType }: DocumentModalContentsProps) => {
  const [encodedPdfString, setEncodedPdfString] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const { getIdToken } = useAuth();

  useEffect(() => {
    const { getDocument } = getConfig()?.footer?.endpoints || {};
    if (!getDocument || !docType) {
      throw new Error(`getDocument (${JSON.stringify(getConfig()?.footer?.endpoints)}) or docType (${docType}) is falsey`);
    }
    const url = `${getDocument}?docType=${docType}&action=view`
    fetch(
      url,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getIdToken()}`,
        }
      }
    ).then((response) => response.text()
    ).then((encodedString) => {
      if (encodedString === "") {
        throw new Error("encoded PDF is empty");
      }
      const pdfBlob = new Blob([Uint8Array.from(window.atob(encodedString), c => c.charCodeAt(0))], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setEncodedPdfString(pdfUrl);
    }).catch((error) => {
      console.error("error: ", error);
      setEncodedPdfString("")
      setError(true);
    });
  }, []);

  return (
    <div
      className="col"
      data-testid="document-modal-contents"
    >
      {!encodedPdfString && !error && <CircularProgress />}
      {encodedPdfString &&
        <div
          className='col pdf-iframe-wrapper'
          data-testid="pdf-iframe-wrapper"
        >
          <iframe
            className="pdf-iframe"
            src={encodedPdfString}
            data-testid="pdf-iframe"
          />
          <Button
            variant="outlined"
            onClick={closeModal}
            sx={{
              marginTop: "20px",
            }}
            data-testid="close-button"
          >
            Close
          </Button>
        </div>
      }
      {error && !encodedPdfString &&
        <Typography variant="body1">
          Error loading document
        </Typography>
      }
    </div>
  );
}

/**
 * Type for props of DocumentModal component.
 */
export type DocumentModalProps = {
  modalIsOpen: boolean,
  closeModal: () => void,
  docType: DocTypes,
};

/**
 * Modal that will fetch and then display a document.
 */
const DocumentModal = ({
  modalIsOpen,
  closeModal,
  docType,
}: DocumentModalProps) => {
  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
    >
      <DocumentModalContents
        closeModal={closeModal}
        docType={docType}
      />
    </Modal>
  );
};

export default DocumentModal;
