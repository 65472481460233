import { Link } from '../FooterLink';

/**
 * List of documents for Footer to link to.
 */
export const documents: Link[] = [
    {
        text: "Terms Of Use",
        linkType: "PDF" as const,
        docType: "TERMS_AND_CONDITIONS" as const,
    },
    {
        text: "Privacy Policy",
        linkType: "HYPERLINK" as const,
        url: "https://www2.deloitte.com/us/en/footerlinks1/deloitte-commercial-asset-privacy-notice.html",
    },
    {
        text: "Third Party Notices",
        linkType: "PDF" as const,
        docType: "THIRD_PARTY_NOTICES" as const,
    },
];
